<template>
  <!-- <div v-if="isAuthenticated" class="pa-0 ma-0"> -->
    <!-- src="@/assets/banner5.jpg" -->
    <v-app-bar
      :v-text="title"
      id="app-bar"
      app
      flat
      fixed
      height="75"
      color="primary"
      :src="$vuetify.theme.dark ? imageDark : imageDefault"
    >
      <v-app-bar-nav-icon @click="setDrawer(!drawer)"></v-app-bar-nav-icon>
      <v-toolbar class="hidden-sm-and-down" dense flat scrollable color="white">
        <v-btn icon>
        <v-img :src="$vuetify.theme.dark ? logoDark : logoDefault" height="70px" width="70px" alt=""></v-img>
      </v-btn>
        <v-toolbar-title class="font-weight-light primary--text headline"><span class="primary--text">{{ title }}</span></v-toolbar-title>
      </v-toolbar>
      <v-spacer />
      <languagePicker @selectLanguageEvent="selectLanguage"/>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              slot="activator"
              class="ml-2"
              min-width="0"
              text
              v-bind="attrs"
              v-on="on">
              <v-row align="center" justify="center">
                <v-col>
                  <v-avatar v-if="isProfileLoaded"
                    class="grey lighten-4">
                    <img v-bind:src="profileImage" v-if="isUserPicture" alt="avatar">
                  </v-avatar>
                </v-col>
                <v-col>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;User:&nbsp;</span> {{ userID }}
                </v-col>
              </v-row>
            </v-btn>
          </template>
          <v-list flat>
            <v-list-item>
              <v-list-item-content>
                <v-list-tile-title @click="account()"><v-icon>perm_identity</v-icon>{{$t('profile')}}</v-list-tile-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-tile-title @click="logout()"><v-icon>lock_open</v-icon>{{$t('logout')}}</v-list-tile-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
    </v-app-bar>
  <!-- </div> -->
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'
  import languagePicker from 'components/navigation/languagePicker.vue'

  // Utilities
  import { mapMutations, mapGetters, mapState } from 'vuex'
  import { AUTH_LOGOUT } from 'actions/auth'
  import { expireTimeTextJwt, toggleFullScreen } from '@/utils'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      languagePicker,
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifications: [
        'Mike John Responded to your email',
        'You have 5 new tasks',
        'You\'re now friends with Andrew',
        'Another Notification',
        'Another one',
      ],
      title: '',
      imageDefault: require('@/assets/banner5.jpg'),
      imageDark: require('@/assets/banner6.jpg'),
      logoDefault: require('@/assets/MeeTeeMeeNgern/logo.jpg'),
      logoDark: require('@/assets/MeeTeeMeeNgern/logo.jpg'),
    }),

    computed: {
      ...mapState(['drawer']),
      ...mapGetters(['getProfile', 'isAuthenticated', 'isProfileLoaded']),
      ...mapState({
        authLoading: state => state.auth.status === 'loading',
        name: state => `${state.user.profile.first_name} ${state.user.profile.last_name}`,
        userID: state => `${state.user.profile.user_id}`,
        picture_base64: state => `${state.user.profile.picture_base64}`,
        token: state => `${state.auth.token}`,
      }),
      tokenExpire: function () {
        return expireTimeTextJwt(this.token)
      },
      isUserPicture () {
        // this.getMenu(this.$i18n.locale)
        return this.picture_base64 !== 'null'
      },
      profileImage () {
        return `data:image/png;base64, ${this.picture_base64}`
      },
    },
    mounted () {
      this.setAppBarHeight(this.$el.clientHeight)
    },
    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      ...mapMutations({
        setAppBarHeight: 'SET_APP_BAR_HEIGHT',
      }),
      selectLanguage (value) {
        this.$emit('selectLanguageEvent', value)
      },
      account () {
        this.$router.push('/account')
      },
      logout: function () {
        this.$swal({
          text: 'คุณต้องการที่จะ logout?',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ใช่',
          cancelButtonText: 'ไม่ใช่'
        }).then((result) => {
          if (result) {
            this.logoutaction()
            this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/login'))
            location.reload()
            /* this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/')) */
          }
        })
      },
      logoutaction () {
        console.log('logout**')
        this.axios.post(process.env.VUE_APP_API + '/user/logout', {
          user_id: '',
        })
          .then(response => {
            if (response.data.isSuccess) {
              console.log(response.data.data, 'response.data.data')
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      getWindowTitle (winName) {
        var component = this.$router.options.routes[2].children.find(function (x) {
          return (x.name === winName)
        })
        if (component !== undefined && component !== null) {
          if (component.title !== undefined && component.title !== null) {
            return component.title
          } else {
            return ''
          }
        } else {
          return ''
        }
      },
      setWindowTitle (text) {
        this.$refs.toolbarTitle.innerText = text
      },
    },
  }
</script>
